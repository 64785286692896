import {cva} from 'class-variance-authority';
import type {ReactNode} from 'react';
import {useState, useId} from 'react';

import {IconEnum} from '@/enums';

import Icon from '../../elements/Icon/Icon';
import Typography from '../../elements/Typography/Typography';

export interface AccordionProps {
  content: ReactNode;
  heading: string;
  id?: string;
  mode?: 'light' | 'dark';
}

const containerStyles = 'max-w-xl md:max-w-2xl lg:max-w-3xl';

const accordionStyles = cva(
  'grid pb-xl border-b transition-all duration-[400ms] ease-[cubic-bezier(0.40,0.00,0.20,1.00)]',
  {
    variants: {
      mode: {
        light: 'text-black',
        dark: 'text-white border-white/30',
      },
      isExpanded: {
        true: 'grid-rows-[max-content_1fr]',
        false: 'grid-rows-[max-content_0fr]',
      },
    },
  },
);

const buttonStyles = cva(
  'grid shrink-0 place-items-center w-8 h-8 md:w-11 md:h-11 ml-auto rounded-full transition-colors duration-500 delay-[50ms]',
  {
    variants: {
      isExpanded: {
        true: 'bg-transparent',
        false: '',
      },
      mode: {
        light: '',
        dark: '',
      },
    },
    compoundVariants: [
      {
        isExpanded: false,
        mode: 'light',
        class: 'bg-black',
      },
      {
        isExpanded: true,
        mode: 'light',
        class: 'border-2 border-black',
      },
      {
        isExpanded: false,
        mode: 'dark',
        class: 'bg-white',
      },
      {
        isExpanded: true,
        mode: 'dark',
        class: 'border-2 border-white',
      },
    ],
  },
);

const panelStyles = cva(
  [
    'pr-8 md:pr-11 md:mr-sm overflow-hidden',
    'transition-opacity duration-[400ms] ease-[cubic-bezier(0.40,0.00,0.20,1.00)]',
    '[&_h4]:text-t7 [&_h4]:mb-2 [&_h4]:mt-md [&_h5]:mb-2 [&_h5]:mt-md [&_h5]:text-t8 [&_h6]:text-t8 [&_h6]:mb-2 [&_h6]:mt-md',
    containerStyles,
  ],
  {
    variants: {
      isExpanded: {
        true: 'opacity-100 delay-200',
        false: 'opacity-0 delay-0 [&>*]:invisible',
      },
    },
  },
);

export default function Accordion({
  content,
  heading,
  id,
  mode = 'light',
}: AccordionProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const componentName = 'accordion';
  const uniqueIdCode = useId();

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    switch (event.key) {
      case 'Enter':
        event.preventDefault();
        setIsExpanded(!isExpanded);
      case 'Space':
        setIsExpanded(!isExpanded);
        break;
      default:
        break;
    }
  };

  return (
    <div
      data-component-name={componentName}
      className={accordionStyles({mode, isExpanded})}
      data-mode={mode}
    >
      <button
        type="button"
        className="flex gap-x-sm place-items-center text-left justify-space-between"
        aria-expanded={isExpanded}
        aria-controls={`panel-${id}-${uniqueIdCode}` || `panel-${uniqueIdCode}`}
        id={`header-${id}-${uniqueIdCode}` || `header-${uniqueIdCode}`}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
      >
        <Typography className={containerStyles} as="h3" size="t5">
          {heading}
        </Typography>
        <div className={buttonStyles({isExpanded, mode})}>
          <Icon
            className="w-5 h-5"
            icon={IconEnum.ExpandCollapse}
            size={20}
            options={{isExpanded: isExpanded, mode: mode}}
          />
        </div>
      </button>
      <div
        className={panelStyles({isExpanded})}
        id={`panel-${id}-${uniqueIdCode}` || `panel-${uniqueIdCode}`}
        role="region"
        aria-labelledby={
          `header-${id}-${uniqueIdCode}` || `header-${uniqueIdCode}`
        }
        aria-hidden={!isExpanded}
      >
        <Typography className="pt-sm" as="div">
          {content}
        </Typography>
      </div>
    </div>
  );
}
